.App .generate-recipe {
  font-size: calc(2vmin + 1rem);
  margin-top: 3rem;
  margin-bottom: 2rem;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0.5rem;
}
.App {
  text-align: center;
}
.title {
  text-transform: uppercase;
}

form {
  width: 100%;
  max-width: 800px;
}
.hidden {
  opacity: 0;
  transition: 1s;
}

.App-header {
  padding: 4vmin 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
}
.center-padding {
  padding: 4vmin;
  transition: opacity 1s;
}

.App-link, a {
  color: #05a9d6;
}
.author {
  color: #02485b;
}

.recipe-content {
  text-align: left;
  width: 74vmin;
}
.recipe-steps {
  margin-top: 10vmin;
}

#main-ingredient {
  width: calc(40px + 40vmin);
}

.adjective,
.cut,
.form,
.seasoning,
.cooking-method {
  font-weight: bold;
}

.nature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
}
.hills-clouds {
  width: 200vw;
  position: absolute;
  top: 2vh;
  animation: zoomIn 3s ease-out 1;
}
@keyframes zoomIn {
  from {
    width: 150vw;
  }
  to {
    width: 200vw;
  }
}
.pot {
  --radius: 20px;
  --danceX: 0.5vh;
  --danceY: 4vh;
  --danceDeg: 10deg;
  width: 30vh;
  height: 30vh;
  position: absolute;
  top: calc(10vw + var(--radius));
  animation: float 6s ease infinite alternate, zoomInPot 4s ease-out 1;
  cursor: pointer;
}
.pot.dancing {
  animation: dance 0.4s ease 3;
  pointer-events: none;
}
@keyframes lidDoneSignal {
  0% {
    transform: translateY(0);
  }
  70% {
    transform: translateY(-1.5vh);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes dance {
  0% {
    transform: translate(0) rotate(0) scale(1);
  }
  15% {
    transform: translate(calc(-1*var(--danceX)), calc(-1*var(--danceY)))
               rotate(calc(-1*var(--danceDeg)))
               scale(1.05);
  }
  50% {
    transform: translate(0) rotate(0) scale(1);
  }
  65% {
    transform: translate(var(--danceX), calc(-1*var(--danceY)))
               rotate(var(--danceDeg))
               scale(1.05);
  }
  100% {
    transform: translate(0) rotate(0) scale(1);
  }
}
@keyframes float {
  0% {
    transform: translateY(var(--radius));
  }
  100% {
    transform: translateY(calc(-1*var(--radius)));
  }
}
@keyframes zoomInPot {
  from {
    width: 20vh;
    height: 20vh;
  }
  to {
    width: 30vh;
    height: 30vh;
  }
}
.lid {
  --lidShakeDeg: 4deg;
  transform-box: fill-box;
  transform-origin: center;
  transform: translateY(0.3vh);
}
.lidShaking .lid {
  animation: lidShake 0.3s ease 1;
}
.pot.dancing .lid {
  animation: lidShake 0.3s ease 5, lidDoneSignal 0.4s ease 1 1.5s;
}
@keyframes lidShake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(calc(-1*var(--lidShakeDeg)));
  }
  50% {
    transform: rotate(calc(1*var(--lidShakeDeg)));
  }
  75% {
    transform: rotate(calc(-1*var(--lidShakeDeg)));
  }
  100% {
    transform: rotate(0deg);
  }
}
@media (min-width: 1080px) {
  .hills-clouds {
    width: 2160px;
  }
  @keyframes zoomIn {
    from {
      width: 1280px;
    }
    to {
      width: 2160px;
    }
  }
}
@media (max-height: 980px) {
  .nature {
    height: 60vh;
  }
  .hills-clouds {
    width: 130vh;
    top: auto;
    bottom: -2vh;
  }
  .pot {
    width: 20vh;
    height: 20vh;
    top: auto;
    bottom: calc(28vh + var(--radius));
  }
  @keyframes zoomIn {
    from {
      width: 100vh;
    }
    to {
      width: 130vh;
    }
  }
  @keyframes zoomInPot {
    from {
      width: 10vh;
      height: 10vh;
    }
    to {
      width: 20vh;
      height: 20vh;
    }
  }
}
